import {
  AppBar,
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import {user} from "./AuthStore";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import css from './root.module.scss';
import {darkTheme} from "./theme";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CollectionsIcon from "@mui/icons-material/Collections";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {newRecipe} from "../recipes/RecipeStore";
import {toast, ToastContainer} from "../../components/toasts/Toasts";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Menu from "@mui/material/Menu";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from "@mui/icons-material/Settings";
import SchemaIcon from "@mui/icons-material/Schema";
import BlenderIcon from '@mui/icons-material/Blender';

const md5 = await import("md5").then(m => m.default);

const showBlender = Math.random() < 0.05;

const RootWithAuth = observer(() => {

  const location = useLocation()
  const nav = useNavigate();

  const userButtonRef = React.useRef<HTMLButtonElement>(null);
  const [userMenuOpened, setUserMenuOpened] = React.useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === '/') {
      if (user.isLoggedIn) {
        nav('/recipes')
      } else {
        nav('/auth')
      }
    }
  }, [user.isLoggedIn, location.pathname]);

  const avatar = <Avatar src={`https://www.gravatar.com/avatar/${md5(user.userData?.email ?? '')}`}/>;

  return (
      <ThemeProvider theme={darkTheme}>

        <Paper className={css.root}>
          <AppBar position="relative" color="primary">
            <Toolbar className={css.toolbar}>
              {!showBlender
                  ? <SchemaIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                  : <BlenderIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
              }

              <Tooltip title={<Typography className={css.logoName}>BUILD: {process.env.BUILD}</Typography>}>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    className={css.logoName}
                >
                  Athena

                  <div className={css.alpha}>Alpha</div>
                </Typography>
              </Tooltip>

              <div style={{marginLeft: '8px'}}/>

              {user.isLoggedIn && (<>
                <Button
                    startIcon={<AddBoxIcon/>}
                    size="large"
                    color="secondary"
                    onClick={() => newRecipe().then(r => nav(`/editor/${r._id}`))}
                >
                  Create
                </Button>

                <Tabs
                    value={location.pathname}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                  <Tab
                      label="Projects"
                      onClick={() => nav('/recipes')}
                      value="/recipes"
                      icon={<AccountTreeIcon/>}
                      iconPosition="start"
                />
                <Tab
                    label="My Generated Images"
                    onClick={() => nav('/gallery')}
                    value="/gallery"
                    icon={<CollectionsIcon/>}
                    iconPosition="start"
                />
                <Tab
                    label="My Uploaded Images"
                    onClick={() => nav('/uploads')}
                    value="/uploads"
                    icon={<CloudUploadIcon/>}
                    iconPosition="start"
                />
                <Tab
                    label="My Models"
                    onClick={() => nav('/models')}
                    value="/models"
                    icon={<DocumentScannerIcon/>}
                    iconPosition="start"
                />
              </Tabs>
            </>)}

            <div style={{marginLeft: 'auto'}}/>

            {user.isLoggedIn && (
                <>
                  <IconButton
                      size="large"
                      color="inherit"
                      ref={userButtonRef}
                      onClick={() => setUserMenuOpened(true)}
                  >
                    {avatar}
                  </IconButton>

                  <Menu
                      anchorEl={userButtonRef.current}
                      open={userMenuOpened}
                      onClose={() => setUserMenuOpened(false)}
                      onClick={() => setUserMenuOpened(false)}
                      PaperProps={{elevation: 0, className: css.menu}}
                      transformOrigin={{horizontal: 'right', vertical: 'top'}}
                      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                  >
                    <MenuItem onClick={() => {
                      toast('Hello! 👋🤗', {
                        // icon: '',
                        style: {
                          fontSize: '2rem',
                          textAlign: 'center',
                        }
                      })
                    }}>
                      {avatar} {user.userData?.email}
                    </MenuItem>
                    <Divider/>
                    <MenuItem disabled>
                      <ListItemIcon>
                        <SettingsIcon fontSize="small"/>
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem onClick={user.logout}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
            )}
          </Toolbar>
        </AppBar>

        <div className={css.content}>
          <Outlet/>
        </div>
        <ToastContainer/>
      </Paper>

      </ThemeProvider>
  );
})

export function Root() {
  return (
        <RootWithAuth/>
  );
}
