import RingLoader from "react-spinners/RingLoader";
import React from "react";
import css from './spinner.module.scss'

export type SpinnerProps = {
  size?: number
  style?: React.CSSProperties,
  className?: string
}
export const SpinnerWithCentering = (props: SpinnerProps) => <div className={css.center}>
  <Spinner {...props}/>
</div>

export const Spinner = (props: SpinnerProps) => <RingLoader {...props} color="#fefefe" loading/>