import {user} from "../pages/root/AuthStore";
import {action, makeObservable, observable, runInAction, when} from "mobx";
import {toast} from "../components/toasts/Toasts";

export const API_URL = process.env.API_URL
export const SOCK_URL = process.env.SOCK_URL

export class APIError extends Error {
  name = 'APIError'

  constructor(readonly code: string, message: string) {
    super(message);
  }
}

export const makeApiPath = (path: string) => `${API_URL}${path}`

export async function api<T>(path: string, init?: Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string>
}): Promise<T> {

  console.log('calling', makeApiPath(path));

  init ||= {};
  init.credentials = 'include';
  init.headers ||= {};
  if (!init.headers['content-type']) {
    if (!(init.body instanceof FormData)) {
      init.headers['content-type'] = 'application/json';
    }
  }

  if (user.token) {
    init.headers['Authorization'] = `Bearer ${user.token}`;
  }

  const r = await fetch(makeApiPath(path), init);

  if (r.status === 401 && user.isLoggedIn) {
    user.logout().catch(console.log);
    toast(
      `Session expired, please login again`,
      {
        type: "error",
      }
    );
    throw new APIError('unauthorized', 'Session expired, please login again');
  } else if (r.status === 401 && !user.isLoggedIn) {
    throw new APIError('unauthorized', 'Session expired, please login again');
  }
  if (r.status >= 400 && r.status < 500) {
    const text = await r.json() as { data: string, error: string, message: string, statusCode: number };
    toast(
      `API Error: ${text.error} (${text.message})`,
      {
        type: "error",
      }
    );
    throw new APIError(text.error, text.message);
  }


  const text = await r.text();

  try {
    const data = JSON.parse(text);

    if (data.error) {
      throw new APIError(data.error, data.message);
    }

    return data;
  } catch (e) {
    console.error(e);
    console.error(text);
    // toast(
    //   `API Error when calling ${path} (${text})`,
    //   {
    //     type: "error",
    //     position: 'bottom-left',
    //     autoClose: 5000,
    //     theme: 'dark'
    //   })
    throw e;
  }
}

export abstract class LoadMoreApi<T> {
  @observable.shallow
  data: T[] = [];

  @observable
  loadingMore = false;

  @observable
  canLoadMore = true;

  protected constructor(readonly limit: number) {
    makeObservable(this);
  }

  abstract get oldestTimestamp(): number | null;

  abstract fetch(olderThan: number, limit: number): Promise<T[]>;

  @action
  reset = () => {
    this.data = [];
    this.canLoadMore = true;
  }

  loadMore = async () => {
    if (!this.canLoadMore) return;
    if (this.loadingMore) return;

    // we're creating a local array here to
    // push stale state on it if search is applied
    const data = this.data;

    try {
      runInAction(() => this.loadingMore = true);

      let lastTimestamp = this.data.length === 0? Date.now() : this.oldestTimestamp;
      if (lastTimestamp === null) {
        await when(() => this.oldestTimestamp !== null, {timeout: 10000});
        lastTimestamp = this.oldestTimestamp;
      }


      const newData = lastTimestamp !== null ? await this.fetch(lastTimestamp, this.limit) : [];

      if(newData.length < this.limit) {
        runInAction(() => this.canLoadMore = false);
      }

      runInAction(() => {
        for (const d of newData) {
          data.push(d);
        }
      });

    } finally {
      runInAction(() => this.loadingMore = false);
    }
  }
}