import {computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {User} from "be-athena/dist/types/users";
import {api, API_URL} from "../../api/_base";
import {router} from "../../main";

export type AuthMethods = 'github' | 'discord'
export type AuthType = 'login' | 'signup'

class AuthStore {
  @observable
  userData: User | null = null;

  @computed
  get isLoggedIn() {
    return !!this.userData;
  }

  @observable
  token: string |null=null;

  constructor() {
    makeObservable(this);

    runInAction(() => {
      this.token = localStorage.getItem('ATHENA_TOKEN') || null;
    });
    reaction(() => this.token, (token) => {
      localStorage.setItem('ATHENA_TOKEN', token || '');
    });
  }

  get = async () => {
    try {
      const {user} = await api<{user: User}>(`/me`);

      runInAction(() => {
        this.userData = user;
      })
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.userData = null
      });

      if(location.pathname!=='/auth') {
        router.navigate('/auth').catch(console.error);
      }
    }
  }

  beginAuthWithMethod(method: AuthMethods, type: AuthType) {
    window.location.href = `${API_URL}/auth/${method}?a_method=${method}&a_type=${type}`;
  }

  async competeAuth() {
    const url = new URL(window.location.href);
    const a_method = url.searchParams.get('a_method') as AuthMethods | undefined;
    const a_type = url.searchParams.get('a_type') as AuthType | undefined;

    if (!a_type || !a_method) return;


    const {user, token} = await api<{user: User, token: string}>(
      `/${a_type}/${a_method}?${url.searchParams.toString()}`,
      {
        credentials: "include",
      }
    )

    runInAction(() => {
      this.userData = user;
      this.token = token;
    })

    window.location.search = '';
  }

  logout = async () => {
    runInAction(() => {
      this.userData = null;
      this.token = null;
    });

    router.navigate('/').catch(console.error);
  }
}

export const user = new AuthStore();

user.get().catch(console.error);
