import {makeObservable} from "mobx";
import {createContext} from "../../helpers/context";
import {Project} from "be-athena/dist/types/project";
import {DataFrame} from "../../api/dataframe";
import {api} from "../../api/_base";
import {MixImageGenerated} from "be-athena/src/types/images";

const {adjectives, animals, colors, uniqueNamesGenerator} = await import("unique-names-generator");


class RecipeStore extends DataFrame<Project[]> {


  constructor() {
    super({autoFetch: true});
    makeObservable(this)
  }

  protected fetch(): Promise<Project[]> {
    return api<Project[]>('/p');
  }

  createRecipe = async () => {
    await api<Project>('/p', {
      method: 'post',
      body: JSON.stringify({
        name: uniqueNamesGenerator({
          dictionaries: [adjectives, colors, animals],
          style: 'capital',
          separator: ' ',
        })
      })
    });

    return this.update();
  }

}

const funnyName = () => uniqueNamesGenerator({
  dictionaries: [adjectives, colors, animals],
  style: 'capital',
  separator: ' ',
})

export async function newRecipe(name: string = funnyName()) {
  return await api<Project>('/p', {
    method: 'post',
    body: JSON.stringify({
      name
    })
  });
}

export const recipeOutputs = (projectId: string) => {
  return api<{images: MixImageGenerated[]}>(`/p/${projectId}/i/generated?olderThan=${Date.now()}&limit=20`, {method: 'GET'}).then(x => x.images);
}

export const {Provider: RecipesProvider, useStore: useRecipes} = createContext(RecipeStore);