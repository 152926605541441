import React, {Suspense} from "react";
import type {ToastContainerProps} from "react-toastify/dist/types";

const _ToastContainer = React.lazy(() => import('./ToastContainer'));

export const ToastContainer = (props: ToastContainerProps) => <Suspense>
  <_ToastContainer
      position="top-center"
      autoClose={5000}
      theme="dark"
      {...props}
  />
</Suspense>

export const {useToast, toast} = await import('react-toastify');