// import 'error-cause/auto'
import React, {lazy} from "react";
import ReactDOM from "react-dom/client";
import './main.css'
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {Root} from "./pages/root/Root";
import {SpinnerWithCentering} from "./components/spinner/Spinner";
import {toJS} from "mobx";

const Spinner = <SpinnerWithCentering size={100}/>

const AuthPage = lazy(() => import("./pages/auth/Auth").then(m => ({default: m.AuthPage})))
const Recipes = lazy(() => import("./pages/recipes/Recipes").then(m => ({default: m.Recipes})))
const Editor = lazy(() => import("./pages/editor/Editor").then(m => ({default: m.Editor})))
const Gallery = lazy(() => import("./pages/gallery/Gallery").then(m => ({default: m.GalleryPage})))
const Models = lazy(() => import("./pages/models/Models").then(m => ({default: m.Models})))

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children: [
      {
        path: "auth",
        element: <React.Suspense fallback={Spinner}><AuthPage/></React.Suspense>,
      },
      {
        path: "recipes",
        element: <React.Suspense fallback={Spinner}><Recipes/></React.Suspense>,
      },
      {
        path: "gallery",
        element: <React.Suspense fallback={Spinner}><Gallery type="generated"/></React.Suspense>,
      },
      {
        path: "uploads",
        element: <React.Suspense fallback={Spinner}><Gallery type="uploaded"/></React.Suspense>,
      },
      {
        path: "editor/:projectId",
        element: <React.Suspense fallback={Spinner}><Editor/></React.Suspense>,
      },
      {
        path: "models",
        element: <React.Suspense fallback={Spinner}><Models/></React.Suspense>,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root')!)
// root.render(<FlowContainer/>)
root.render(<RouterProvider router={router}/>)

// @ts-ignore
window.toJS = toJS